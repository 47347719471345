import './SecondSection.css';


const cat_cards = [
    {
        text: 'Дохід у наших котиків з першого місяця співпраці',
        image: './svg/cat-money1.svg',
        header: 'ВИСОКИЙ ДОХІД',
        img_position: 'left'
    },
    {
        text: 'Працюй знаходячись у зручному офісі',
        image: './svg/cat-2.svg',
        header: 'РОБОТА НЕ ВІДДАЛЕНА',
        img_position: 'right'
    },
    {
        text: 'Розвивайся та зростай в даній сфері',
        image: './svg/cat-3.svg',
        header: 'КАРʼЄРНЕ ЗРОСТАННЯ',
        img_position: 'left'
    },
    {
        text: 'Ставай чудовим співрозмовником, який легко знаходить спільні теми з будь-ким',
        image: './svg/cat-4.svg',
        header: 'РОЗВИТОК КРЕАТИВНОСТІ',
        img_position: 'right'
    }
];

const SecondSection = () => {

  return (
    <>
        <div className='second-section'>
            <div className='header-second-section'>
                Бути чат-менеджером - це цікаво та перспективно
            </div>
            <div className='content-second-section'>
                {CatsCard(cat_cards[0].text, cat_cards[0].image, cat_cards[0].header, cat_cards[0].img_position)}
                {CatsCard(cat_cards[1].text, cat_cards[1].image, cat_cards[1].header, cat_cards[1].img_position)}
                {CatsCard(cat_cards[2].text, cat_cards[2].image, cat_cards[2].header, cat_cards[2].img_position)}
                {CatsCard(cat_cards[3].text, cat_cards[3].image, cat_cards[3].header, cat_cards[3].img_position)}
            </div>
        </div>
    </>
  );
};

const CatsCard = (text, image, header, img_position) => {

    const left = img_position === 'left' ? -20 : 'auto';
    const right = img_position === 'left' ? 'auto' : -20;

    return (
        <>
            <div className='cat-card'>

                <div className='cat-card-image'>
                    <img src={image} alt="Logo" style={{ left: left, right: right }}></img>
                </div>
                <div className='cat-card-header'>
                    {header}
                </div>
                <div className='cat-card-text'>
                    {text}
                </div>
                
            </div>
        </>
    );
};

export default SecondSection;
