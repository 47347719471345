import './FirstSection.css';
import { useState, useEffect } from 'react';

const FirstSection = () => {

  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > window.innerHeight);


  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    if (window.innerWidth <= window.innerHeight) {
        setIsWideScreen(false);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const scrollToElement = () => {
    const targetElement = document.querySelector('#bottom_container');
    if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
};

  return (
    <>
        <div className='first-section'>
            <div className='header-first-section'>
            <div className='left-header-cont'>
                <img src="./svg/logo.svg" alt="Logo"></img>
            </div>
            <div className='right-header-cont'>
                <a href="https://t.me/Evgeniya_788" target="_blank" rel="noopener noreferrer">
                    <div className='header-tg-logo-cont'>
                        <img src="./svg/telegram.svg" alt="Logo"></img>
                    </div>
                </a>
            </div>

            </div>
            <div className='content-first-section'>
            {isWideScreen ? (
                <div className='wide-screen-content'>
                <div className='content-first-section-left'>
                    <div className='content-first-section-left-text'>Стань чат-менеджером та заробляй</div>
                    <div className='content-first-section-left-sticker'>від 500$</div>
                    <div className='content-first-section-left-button-container' onClick={() => { scrollToElement()}}>
                        <div className='content-first-section-left-button' >
                            Хочу у команду!
                        </div>
                    </div>
                </div>
                <div className='content-first-section-right'>
                    <img src="./svg/cat-bg.svg" alt="cat-first-section"></img>
                </div>
                </div>
            ) : (
                <div className='narrow-screen-content'>
                    <div className='content-first-section-header'>
                        <div className='content-first-section-text'>Заробляй</div>
                        <div className='content-first-section-left-sticker'>від 500$</div>
                    </div>
                    <div className='content-first-section-right'>
                        <img src="./svg/cat-bg.svg" alt="cat-first-section"></img>
                    </div>
                    <div className='content-first-section-left-button-container' onClick={() => { scrollToElement()}}>
                        <div className='content-first-section-left-button' >
                            Хочу у команду!
                        </div>
                    </div>
                </div>
            )}
            </div>
        </div>
    </>
  );
};

export default FirstSection;
