import './FifthSection.css';

const FifthSection = () => {

    const redirectToGoogleForms = () => {
        window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLScrMeSP7EEzTotOof3jr_r_DNigbYwIqTsXB5MFaHD5ZlsDVg/viewform?usp=sf_link'; 
    };



    return (
        <>
        <div className='fifth-section' id='bottom_container'>
            <div className='content-fifth-section'>
                <div className='image-fifth-section'>
                    <img src='./svg/just.svg' alt="Cats"></img>
                </div>
                <div className='button-fifth-section'>
                    <div className='content-first-section-left-button-container' onClick={redirectToGoogleForms}>
                        <div className='content-first-section-left-button'>
                            Записатися на співбесіду
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default FifthSection