import './FourthSection.css';
// import { useState, useEffect } from 'react';

const career_cards = [
    {
        text: 'робить наших клієнтів щасливими',
        image: './svg/cat-career1.svg',
        header: 'Чат-менеджер',
        background_img: './svg/lapka1.svg',
        margin_top: 120
    },
    {
        text: 'навчає заробляти нових співробітників',
        image: './svg/cat-career2.svg',
        header: 'Менеджер',
        background_img: './svg/lapka2.svg',
        margin_top: 80
    },
    {
        text: 'справжній профі, який будує свою команду тімлідів',
        image: './svg/cat-career3.svg',
        header: 'ТОП-менеджер',
        background_img: './svg/lapka3.svg',
        margin_top: 40
    }
];

const FourthSection = () => {

    const isWideScreen = window.innerWidth >= 1000;

  return (
    <>
        <div className='fourth-section'>
            <div className='header-fourth-section'>  
                Кар'єрний зріст
            </div>
            <div className='content-fourth-section'>
                {career_cards.map((career, index) => {
                    return <CareerCard key={index} {...career} isWideScreen={isWideScreen} index={index} />
                })}
            </div>
            <div className='background-fourth-section'>
                <img src="./svg/lapki-pattern.svg" alt="Logo"></img>
            </div>
        </div>
    </>
  );
};

const CareerCard = ({ header, text, image, background_img, margin_top, isWideScreen, index }) => {
    const flex_direction = index === 1 ? 'row-reverse' : 'row';

    return (
        <>
            <div className='career-card'  style={isWideScreen ? { backgroundImage: `url(${background_img})`, backgroundSize: '200px', backgroundPosition: `50% ${margin_top - (margin_top / 3) - 30}%`, backgroundRepeat: 'no-repeat' } : { flexDirection: flex_direction} }>
                <div className='career-card-cont' style={isWideScreen ? { marginTop: `${margin_top}%` } : null }>
                    <div className='career-card-header'>
                        {header}
                    </div>
                    <div className='career-card-text'>
                        {text}
                    </div>
                </div>
                <div className='career-card-image'>
                    <img src={image} alt="Cats"></img>
                </div>
            </div>
        </>
    );
};

export default FourthSection;
