import './ThirdSection.css';
// import { useState, useEffect } from 'react';

const info_cards = [
    {
        text: 'Маєш можливість отримати виплату наприкінці кожного тижня під час стажування',
        image: './svg/money-bag.svg',
    },
    {
        text: 'Декілька варіантів отримання ЗП: \n - У гривні \n - У іноземній валюті',
        image: './svg/dollar-tree.svg',
    },
    {
        text: 'Персональне робоче місце',
        image: './svg/tie.svg',
    }
];

const ThirdSection = () => {

  return (
    <>
        <div className='third-section'>
            <div className='header-third-section'>
                Наші особливості
            </div>
            <div className='content-third-section'>
                {InfoCard(info_cards[0].text, info_cards[0].image)}
                {InfoCard(info_cards[1].text, info_cards[1].image)}
                {InfoCard(info_cards[2].text, info_cards[2].image)}
            </div>
        </div>
    </>
  );
};

const InfoCard = (text, image) => {


    return (
        <>
            <div className='info-card'>

                <div className='info-card-image'>
                    <img src={image} alt="Logo"></img>
                </div>
                <div className='info-card-text'>
                    {text}
                </div>
                
            </div>
        </>
    );
};

export default ThirdSection;
